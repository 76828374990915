table {
  &.form-inputs {
    .alert {
      display: inline-block;
      @include fs(-1);
      padding: $unit/2 $unit;
      margin-left: $unit/2;
      color: red;
    }

    tbody {
      tr {
        border-bottom: 1px dotted whitesmoke;
        th, td {
          padding: $unit/2;
        }

        th {
          white-space: nowrap;
        }

      }

    }
  }
}