.page-login-auth {
  .nav-help {
    padding: $unit 0;
    text-align: center;
    a {
      display: inline-block;
      padding: 0 $unit;
      color: #4d515a;
      text-decoration: none;
      &:hover {
        color: blue;
      }
    }
  }
}