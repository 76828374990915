@charset "UTF-8";
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
  font-size: 1.6rem; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

li {
  list-style: none; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0;
  font-variant: normal; }

sup {
  vertical-align: text-top; }

sub {
  vertical-align: text-bottom; }

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

* {
  box-sizing: border-box; }

html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%; }

html {
  background: whitesmoke;
  font-size: 62.5%; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif; }

/****************************************************************************
 * ヘッダ
 ****************************************************************************/
.l-header {
  width: 100%;
  padding: 1.6rem 0; }
  .l-header .outline {
    max-width: 960px;
    margin: 0 auto;
    align-items: end; }
    .l-header .outline .brand {
      text-align: center; }
      .l-header .outline .brand .app-name {
        font-weight: bold;
        width: 256px;
        text-align: center;
        display: inline-block; }
      .l-header .outline .brand .desc {
        color: #666666;
        font-size: 1.4rem; }

/****************************************************************************
 * メイン
 ****************************************************************************/
main {
  padding: 6.4rem; }
  main .content-default {
    max-width: 800px;
    margin: 0 auto;
    background: white;
    border-radius: 4px;
    box-shadow: 0 1px 4px -2px rgba(0, 0, 0, 0.1); }
    main .content-default header {
      padding: 3.2rem 1.6rem;
      border-bottom: 1px solid whitesmoke; }
      main .content-default header .title {
        font-size: 3rem;
        text-align: center; }
    main .content-default .body {
      padding: 1.6rem; }
    main .content-default footer {
      padding: 1.6rem;
      border-top: 1px solid whitesmoke; }
      main .content-default footer .actions {
        text-align: center; }

/****************************************************************************
 * パンくず
 ****************************************************************************/
.nav-breadcrumbs {
  font-size: 1.4rem;
  padding: 0.8rem 1.6rem;
  background: whitesmoke; }
  .nav-breadcrumbs a {
    text-decoration: none;
    color: black;
    transition: all .4s; }
    .nav-breadcrumbs a:hover {
      color: #4b7bec; }

/****************************************************************************
 * フッタ
 ****************************************************************************/
.l-footer {
  background: #4d515a;
  color: white;
  padding: 1.6rem; }
  .l-footer .nav-pages {
    text-align: center; }
    .l-footer .nav-pages a {
      color: white;
      text-decoration: none;
      display: inline-block;
      padding: 0 1.6rem; }
      .l-footer .nav-pages a:hover {
        text-decoration: underline; }
  .l-footer .copyright {
    margin-top: 1.6rem;
    text-align: center; }

table.form-inputs .alert {
  display: inline-block;
  font-size: 1.4rem;
  padding: 0.8rem 1.6rem;
  margin-left: 0.8rem;
  color: red; }

table.form-inputs tbody tr {
  border-bottom: 1px dotted whitesmoke; }
  table.form-inputs tbody tr th, table.form-inputs tbody tr td {
    padding: 0.8rem; }
  table.form-inputs tbody tr th {
    white-space: nowrap; }

.page-login-auth .nav-help {
  padding: 1.6rem 0;
  text-align: center; }
  .page-login-auth .nav-help a {
    display: inline-block;
    padding: 0 1.6rem;
    color: #4d515a;
    text-decoration: none; }
    .page-login-auth .nav-help a:hover {
      color: blue; }
