/****************************************************************************
 * ヘッダ
 ****************************************************************************/
.l-header {
  width: 100%;
  padding: $unit 0;
  .outline {
    max-width: 960px;
    margin: 0 auto;
    align-items: end;

    .brand {
      text-align: center;
      .app-name {
        font-weight: bold;
        width: 256px;
        text-align: center;
        display: inline-block;
      }

      .desc {
        color: lighten(black, 40%);
        @include fs(-1);
      }
    }


  }
}


/****************************************************************************
 * メイン
 ****************************************************************************/
main {
  padding: $unit*4;
  .content-default {
    max-width: 800px;
    margin: 0 auto;
    background: white;
    border-radius: 4px;
    box-shadow: 0 1px 4px -2px rgba(black, .1);
    header {
      padding: $unit*2 $unit;
      border-bottom: 1px solid whitesmoke;
      .title {
        @include fs(3);
        text-align: center;
      }
    }

    .body {
      padding: $unit;
    }

    footer {
      padding: $unit;
      border-top: 1px solid whitesmoke;
      .actions {
        text-align: center;
      }
    }
  }
}
/****************************************************************************
 * パンくず
 ****************************************************************************/
.nav-breadcrumbs {
  @include fs(-1);
  padding: $unit/2 $unit;
  background: whitesmoke;

  a {
    text-decoration: none;
    color: black;
    transition: all .4s;
    &:hover {
      color: #4b7bec;
    }
  }
}
/****************************************************************************
 * フッタ
 ****************************************************************************/
.l-footer {
  background: #4d515a;
  color: white;
  padding: $unit;
  .nav-pages {
    text-align: center;
    a {
      color: white;
      text-decoration: none;
      display: inline-block;
      padding: 0 $unit;
      &:hover {
        text-decoration: underline;
      }

    }
  }
  .copyright {
    margin-top: $unit;
    text-align: center;
  }
}







